<template>
  <div class="w-p-100 h-p-100 bg-f3">
    <div class="h-p-100">
      <van-pull-refresh
        v-model="loading"
        @refresh="refresh"
        @load="onLoad"
        :finished="finished"
        finished-text="没有更多了"
        class="h-p-100 scroll-y"
      >
        <div v-if="list.length > 0" class="w-p-100 h-p-100">
          <div
            class="w-calc-48 Fbg lP-24 rP-24 bM-4 tP-5 bP-5 relative"
            v-for="(item, index) in list"
            :key="index"
            @click="
              router.push(
                '/AnswerDetails/' +
                  item.ct_train_id +
                  '/' +
                  item.ct_operator_info_id +
                  '/1'
              )
            "
          >
            <div class="h-45 flex alignCenter justifyBetween">
              <div class="size-20">{{ item.ct_operator_info_id_name }}</div>
              <div class="size-18 color-80">
                {{ item.ct_contractor_info_name }}
              </div>
            </div>
            <div class="h-35 flex alignCenter size-18 color-80">
              签到时间:{{ item.attendance_time }}
            </div>
            <div class="h-35 flex alignCenter justifyBetween">
              <div class="h-35 flex alignCenter size-18 color-80">
                首次考试成绩:{{ item.first_score }}
              </div>
              <div class="h-35 flex alignCenter size-18 color-80">
                订正次数:{{ item.correct_num }}
              </div>
            </div>
            <div
              class="h-35 flex alignCenter justifyEnd size-18 color-80"
              :class="{
                'color-2FB234': item.is_qualified == 1,
                'color-DFAE00': item.is_qualified == 0,
                'color-80': item.is_qualified == -1
              }"
            >
              {{ item.is_qualified_name }}
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty description="暂无数据"></van-empty>
        </div>
      </van-pull-refresh>
    </div>
    <!-- <div class="h-35 size-18 w-p-100 flex alignCenter justifyCenter bg-0F60A7 color-fff padding-5" @click="onSubmit">下载签到表</div> -->
  </div>
</template>
<script>
/* eslint-disable */
import { onMounted, reactive ,ref} from 'vue';
import http from '../../../api/http';
import { useRoute, useRouter } from 'vue-router';
export default {
    setup() {
        const route = useRoute();
        const router = useRouter();
        const list = ref([])
        const query = reactive({
            name:'',
            page:1,
            pageSize:20
        });
        const finished = ref(false)
        const loading = ref(false);
        const lodingmore = ref(false)
        const onSearch = (page) =>{
            query.page = page;
            loading.value = false;
            http.get({
                method:'query',
                queryId:1068,
                ct_train_lesson_id:route.params.id
            })
            .then(res=>{
                list.value = res.map;
            })
            // finished.value = true;
        }
        // 下拉刷新
        const refresh = () =>{
            loading.value = true;
            onSearch(1)
        }
        // 
        const onLoad = ()=>{
            
        }
        onMounted(()=>{
            onSearch(1)
        });
        return {
            query,
            onSearch,
            refresh,
            finished,
            loading,
            list,
            onLoad,
            route,router,
            lodingmore
        }
    },
}
</script>